// body {
//   font-family: "Inter", sans-serif !important;
// }
.chakra-radio__control,
.chakra-checkbox__control {
  width: 24px !important;
  height: 24px !important;
}
.show-icon .chakra-select__icon-wrapper{
display: inline-flex!important;
}
.chakra-select:hover {
  border:none!important
}
.css-11u5w3t{
  z-index: 0 !important;
}
.css-xxkadm{
  border: 2px solid rgba(26, 26, 26, 0.16) !important;
}
.css-xxkadm[aria-checked=true], .css-xxkadm[data-checked]{
  border: none !important;
}
.chakra-checkbox__control {
  border-radius: 5px !important;
}
input {
  background-color: rgba(216, 216, 217, 0.36)!important;
}
.css-xxkadm[aria-checked=true][disabled], .css-xxkadm[data-checked][disabled], .css-xxkadm[aria-checked=true][aria-disabled=true], .css-xxkadm[data-checked][aria-disabled=true], .css-xxkadm[aria-checked=true][data-disabled], .css-xxkadm[data-checked][data-disabled] {
  background: rgba(#0070C7, 0.36) !important;
  border: none !important;
  color: white !important;
}
.redux-toastr .top-right {
  width: 510px !important;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  margin-top: 25px !important;
  margin-left: 1rem;
}
.redux-toastr .toastr .rrt-middle-container {
  width: 100% !important;
  margin-left: 0;
  text-align: center;
}
.redux-toastr .toastr.rrt-info {
  background-color: #0072cf !important;
}
.redux-toastr .top-center,
.redux-toastr .bottom-center {
  left: 40% !important;
}
.redux-toastr .toastr {
  border-radius: 5px;
  min-width: 573px;
  min-height: auto;
  padding: 10px;
  .rrt-left-container {
    display: none;
  }
  .redux-toastr .toastr .rrt-middle-container {
    width: 100%;
  }
}

.swal2-cancel {
  background-color: #bcbfc4 !important;
}
.swal2-confirm {
  background-color: #0072CF !important;
}
.swal2-confirm:hover {
  background-color: rgba(70, 171, 249, 0.8) !important;
}

.swal2-container {
  z-index: 1440 !important;
}

.chakra-select__icon-wrapper {
  display: none !important;
}
.mySwiper {
  // border-radius: 40px;
  // background-color: #f7f9fd;
  // .swiper-slide {
  //   width: 170.333px !important;
  // }
  .swiper-wrapper {
    padding: 10px 10px 10px 10px;
  }
}
// .css-70qvj9{
//   min-width: 500px !important;
// }
iframe {
  display: none!important;
}
.react-datapicker__input-text{
  background-color: white;
  height: 51px;
  border: 1px solid;
  border-color: rgba(216, 216, 217, 1);
  border-radius: 5px;
  padding-left: 15px;
}
.w300{
  min-width: 300px !important;
}
.w500{
  min-width: 500px !important;
}